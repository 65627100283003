<template>
  <div class="content-container">
    <PageTitle :title="$t('INSTRUCTIONS.TITLE_CREATE_TOP')" class="page-title"/>

    <h1 class="page-section-title section-title">{{ $t('INSTRUCTIONS.NEW') }}</h1>
    <BackButton :text="$t('BACK_OVERVIEW')" @click="attemptLeave()"/>

    <!-- Form -->
    <FormWrapper :send-form-data="validateData"
                 class="page-form"
                 :on-form-data-update="updatedForm">
      <template #form-content>
        <!-- Title -->
        <InputField :field-title="$t('INSTRUCTIONS.TITLE_CREATE')"
                    :field-name="'title'"
                    :rules="'required'"
                    :type="'text'"
                    :placeholder="$t('INSTRUCTIONS.TITLE_PLACEHOLDER')"
                    :cy-selector="'name-input'"
                    :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                    :api-error-text="''"
                    class="form-element"/>

        <!-- Instruction -->
        <!-- <InputField :field-title="$t('INSTRUCTIONS.INSTRUCTION')"
                    :field-name="'content'"
                    :rules="'required'"
                    :type="'text'"
                    :placeholder="$t('INSTRUCTIONS.INSTRUCTION_PLACEHOLDER')"
                    :cy-selector="'name-input'"
                    :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                    :api-error-text="''"
                    class="form-element"/> -->

        <div class="rich-text-field-wrapper">
          <RichTextField :field-title="$t('INSTRUCTIONS.INSTRUCTION')"
                         :input-callback="instructionInput"
                         placeholder="Voer de instructie in"
                         :error-text="richTextError"
          />
        </div>

        <!-- Status -->
        <dropdown
          :field-title="$t('INSTRUCTIONS.STATUS')"
          :field-name="'status'"
          :dropdown-options="[{id:0,name:$t('INSTRUCTIONS.CONCEPT')},{id:1,name:$t('INSTRUCTIONS.PUBLIC')}]"
          :placeholder="$t('INSTRUCTIONS.CONCEPT')"
          :callback="statusCallback"
          :selected-option="0"
          class="form-element"/>
      </template>

      <template #button-submit>
        <button type="submit" class="pink-button submit-button">
          {{ $t('SAVE') }}
        </button>
      </template>

    </FormWrapper>
    <LeaveEarlyModal :values-to-check="leaveEarlyValuesToCheck" :result-callback="leaveEarlyResult"/>
  </div>
</template>

<script>
import nl from '@/utils/language/nl.json'
import PageTitle from '@/components/elements/texts/PageTitle'
import InputField from '@/components/yo-form/InputField'
import FormWrapper from '@/components/yo-form/FormWrapper'
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'
import BackButton from '@/components/elements/BackButton'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { CREATE_INSTRUCTION } from '@/store/modules/cms/actions'
import Dropdown from '../../../components/yo-form/Dropdown.vue'
import RichTextField from '@/components/elements/RichTextEditor/RichTextField'
import { ref } from '@vue/reactivity'
import LeaveEarlyModal from '@/components/elements/basicModal/LeaveEarlyModal'
export default {
  name: 'CreateInstructions',
  components: {
    BackButton,
    FormWrapper,
    PageTitle,
    InputField,
    Dropdown,
    RichTextField,
    LeaveEarlyModal
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const instructionValue = ref('')
    const richTextError = ref('')
    const leaveEarlyValuesToCheck = ref([])
    const currentFormData = ref({})


    function updatedForm(data) {
      currentFormData.value = data
    }

    /** Form **/
    function validateData(data) {
      data.content = instructionValue.value
      if (!data.content || data.content === '') {
        richTextError.value = nl.INSTRUCTIONS.NO_INSTRUCTION_GIVEN
        return false
      }

      data.isActivated = data.status === nl.INSTRUCTIONS.PUBLIC
      store.dispatch(CREATE_INSTRUCTION, data).then(result => {
        if (result) {
          router.push({ name: ROUTE_NAMES_CMS.INSTRUCTIONS })
        }
      })
    }

    function attemptLeave() {
      leaveEarlyValuesToCheck.value = [currentFormData.value.title, instructionValue.value]
    }

    function leaveEarlyResult(value) {
      if (value) {
        router.push({ name: ROUTE_NAMES_CMS.INSTRUCTIONS })
      } else {
        leaveEarlyValuesToCheck.value = []
      }
    }

    function instructionInput(value) {
      instructionValue.value = value
    }

    function statusCallback() {
    }

    return {
      ROUTE_NAMES_CMS,
      instructionInput,
      richTextError,
      /** Form **/
      statusCallback,
      validateData,
      leaveEarlyValuesToCheck,
      leaveEarlyResult,
      updatedForm,
      attemptLeave,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.address-columns {
  margin-bottom: rem(28);
}

.info-box {
  margin-bottom: rem(24);
  margin-top: rem(24);
}

.rich-text-field-wrapper {
  margin-bottom: rem(28);
}
</style>
